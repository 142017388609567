import React from 'react'
import { Field, FormikProps } from 'formik'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { Button } from '../../../../../components/Button'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { InfoPopover } from '../../../../../components/InfoPopover'
import { Link as ChakraLink } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { B1_INITIAL_VALUES } from 'shared'
import { RadioInputPanel } from '../../../../../components/RadioInputPanel'
import RadioInput from '../../../../../components/inputs/RadioInput'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1M: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns title="m) Seznam pozemků podle katastru nemovitostí, na kterých se stavba umísťuje a provádí" sectionKey="b1m">
      <div className="sm:col-span-6">
        <Field name="b1m.landList" component={RichTextInput} />
      </div>
    </FormSectionTwoColumns>
  )
}
