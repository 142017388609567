import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { InfoPopover } from '../../../../../components/InfoPopover'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { RADON_OPTIONS } from '../constants'
import { RadioInputPanel } from '../../../../../components/RadioInputPanel'
import { DropzoneInput } from '../../../../../components/inputs/DropzoneInput'
import { ArrayForm } from '../../../../../components/ArrayForm'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { MinusIcon, PlusIcon, TrashIcon } from '@heroicons/react/20/solid'
import { Button } from '../../../../../components/ui/button'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Ev2: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns
      sectionKey={'b1e'}
      title="e) informace o nutnosti povolení výjimky z požadavků na výstavbu"
    >
      <div className="mt-5 sm:col-span-6">
        {formik.values.b1e.issuedExceptions.map((exception: any, index: number) => {
          const isLast = formik.values.b1e.issuedExceptions.length === index + 1
          const isFirst = index === 0
          const isEmpty = formik.values.b1e.issuedExceptions.length === 0
          const isOnly = formik.values.b1e.issuedExceptions.length === 1

          return (
            <div className="border rounded-md p-3 mb-3" key={index}>
              <div className={`sm:col-span-6 ${!isFirst && 'mt-4'}`}>
                <Field
                  component={TextInput}
                  name={`b1e.issuedExceptions[${index}].name`}
                  label="Název výjimky"
                />
                <div className="mt-4">
                  <Field
                    component={RichTextInput}
                    name={`b1e.issuedExceptions[${index}].description`}
                  />
                </div>
                <div className="mt-4">
                  <Field
                    component={DropzoneInput}
                    name={`b1e.issuedExceptions[${index}].files`}
                    label="Dokumenty"
                    fileLabel={`b1e - Výjimky ${exception.name ? ' - ' + exception.name : ''}`}
                  />
                </div>
                <div className="mt-4 text-right mb-5">
                  <Button
                    type="button"
                    variant="destructive"
                    size="sm"
                    className="mr-2"
                    onClick={() => {
                      if (window.confirm('Skutečně smazat?')) {
                        return formik.setFieldValue(
                          'b1e.issuedExceptions',
                          formik.values.b1e.issuedExceptions.filter(
                            (_: any, i: number) => i !== index,
                          ),
                        )
                      }
                    }}
                  >
                    <TrashIcon className="h-6 inline" />
                  </Button>
                </div>
              </div>
            </div>
          )
        })}
        <div className="flex justify-end">
          <Button
            type="button"
            onClick={() =>
              formik.setFieldValue('b1e.issuedExceptions', [
                ...formik.values.b1e.issuedExceptions,
                {},
              ])
            }
          >
            <PlusIcon className="h-6 inline" />
            Přidat výjimku
          </Button>
        </div>
      </div>
    </FormSectionTwoColumns>
  )
}
