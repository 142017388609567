import React from 'react'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel, DropdownMenuSeparator,
  DropdownMenuTrigger
} from "./ui/dropdown-menu";
import {AlignJustifyIcon} from "lucide-react";

interface Props {
  options: {
    label: string
    target: string
    className?: string
  }[],
  paddingTop?: number
}

export const QuickNavigationDropdown: React.FC<Props> = (props) => {
  const onItemClicked = (target: string) => {
    // Scroll to target based on ID
    const element = document.getElementById(target)

    if (!element) {
      return
    }

    const y = element.getBoundingClientRect().top + window.scrollY - (props.paddingTop ?? 75);
    window.scrollTo({top: y, behavior: 'smooth'});
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <div className="rounded-full bg-gray-100/80 p-3 backdrop-blur border border-gray-300 hover:border-blue-600 hover:bg-blue-600 hover:text-white transition-colors ease-in-out duration-300">
          <AlignJustifyIcon size={16}/>
        </div>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Rychlá navigace</DropdownMenuLabel>
        <DropdownMenuSeparator/>
        {props.options.map((option, index) => (
          <DropdownMenuItem className={option.className} onClick={() => onItemClicked(option.target)} key={index}>{option.label}</DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
