import React from 'react'
import { Field, FormikProps } from 'formik'
import { TextInput } from '../../../../../components/inputs/TextInput'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { Button } from '../../../../../components/Button'
import { MinusIcon, PlusIcon } from '@heroicons/react/20/solid'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'
import { InfoPopover } from '../../../../../components/InfoPopover'
import { Link as ChakraLink } from '@chakra-ui/react'
import { ExternalLinkIcon } from '@chakra-ui/icons'
import { CheckboxInput } from '../../../../../components/inputs/CheckboxInput'
import { B1_INITIAL_VALUES } from 'shared'
import { DropzoneInput } from '../../../../../components/inputs/DropzoneInput'
import { RadioInputPanel } from '../../../../../components/RadioInputPanel'
import { RADON_OPTIONS } from '../constants'
import { ArrayForm } from '../../../../../components/ArrayForm'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1Dv2: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns sectionKey={'b1d'} title="d) výčet a závěry průzkumů">
      <div className="sm:col-span-6">
        <ul role="list" className="">
          {/* Geological Survey */}
          <li className="px-4 py-4 sm:px-6">
            <Field
              component={CheckboxInput}
              name="b1d.geologicalSurvey"
              label="Geologický průzkum"
            />
            {formik.values.b1d.geologicalSurvey && (
              //   TODO: + SOUBORY
              <div className="mt-3">
                <Field component={RichTextInput} name="b1d.geologicalSurveyConclusion" label="" />
                <Field component={DropzoneInput} name="b1d.geologicalSurveyFiles" label="" />
              </div>
            )}
          </li>

          {/* Hydrogeological Survey */}
          <li className="px-4 py-4 sm:px-6">
            <Field
              component={CheckboxInput}
              name="b1d.hydrogeologicalSurvey"
              label="Hydrogeologický průzkum"
            />
            {formik.values.b1d.hydrogeologicalSurvey && (
              <div className="mt-3">
                <Field
                  component={RichTextInput}
                  name="b1d.hydrogeologicalSurveyConclusion"
                  label=""
                />
                <Field component={DropzoneInput} name="b1d.hydrogeologicalSurveyFiles" label="" />
              </div>
            )}
          </li>

          <li className="px-4 py-4 sm:px-6">
            <Field
              component={CheckboxInput}
              name="b1d.engineerGeologySurvey"
              label="Inženýrsko-geologický průzkum"
            />
            {formik.values.b1d.engineerGeologySurvey && (
              <div className="mt-3">
                <Field
                  component={RichTextInput}
                  name="b1d.engineerGeologySurveyConclusion"
                  label=""
                />
                <Field component={DropzoneInput} name="b1d.engineerGeologySurveyFiles" label="" />
              </div>
            )}
          </li>

          {/* Historical Building Survey */}
          <li className="px-4 py-4 sm:px-6">
            <Field
              component={CheckboxInput}
              name="b1d.historicalBuildingSurvey"
              label="Stavebně historický průzkum"
            />
            {formik.values.b1d.historicalBuildingSurvey && (
              <div className="mt-3">
                <Field
                  component={RichTextInput}
                  label=""
                  name="b1d.historicalBuildingSurveyConclusion"
                />
                <Field
                  component={DropzoneInput}
                  name="b1d.historicalBuildingSurveyFiles"
                  label=""
                />
              </div>
            )}
          </li>

          {/* Radon Measurement */}
          <li className="px-4 py-4 sm:px-6">
            <div className="flex">
              <Field component={CheckboxInput} name="b1d.radonMeasurement" label="Měření radonu" />
              <InfoPopover content="Pokud je pod stavbou bez ohledu na kategorii radonového indexu stavby vytvořena drenážní vrstva o vysoké propustnosti nebo je součástí kontaktní konstrukce podlahové vytápění, musí být provedena kombinace s větracím systémem podloží nebo s ventilační vrstvou v kontaktní konstrukci." />
            </div>

            {formik.values.b1d.radonMeasurement && (
              <div className="mt-3">
                <Field
                  name="b1d.radonMeasurementLevel"
                  component={RadioInputPanel}
                  options={RADON_OPTIONS}
                />
              </div>
            )}
          </li>
          <ArrayForm
            name="b1d.custom"
            rows={formik.values.b1d.custom || []}
            setFieldValue={formik.setFieldValue}
            initializationObject={{ name: '', enabled: false, conclusions: '', files: [] }}
          >
            {(name: string, row: any, index: number) => (
              <li className={`px-4 py-4 sm:px-6`}>
                <div className="flex w-100">
                  <Field name={`${name}.enabled`} component={CheckboxInput} />
                  <div className="-mt-3 w-full">
                    <Field name={`${name}.name`} component={TextInput} placeholder="Vlastní" />
                  </div>
                </div>
                {row.enabled && (
                  <div className="mt-5">
                    <Field
                      name={`${name}.conclusions`}
                      component={RichTextInput}
                      label="Opatření"
                    />
                    <Field name={`${name}.files`} component={DropzoneInput} label="Opatření" />
                  </div>
                )}
              </li>
            )}
          </ArrayForm>
        </ul>
      </div>
    </FormSectionTwoColumns>
  )
}
