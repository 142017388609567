import React, { PropsWithChildren } from 'react'
import { useProject } from '../../../../../model/hooks/useProject'
import { useTechnicalReportForm } from '../../../../../model/hooks/useTechnicalReportForm'
import { ProjectFormEnum } from '../../../../../model/enums/ProjectFormEnum'
import { ProjectLoadingSkeleton } from '../../../layout/ProjectLoadingSkeleton'
import { ProjectLayout } from '../../../layout/ProjectLayout'
import { Form, Formik } from 'formik'
import { TechnicalFormHeading } from '../TechnicalFormHeading'
import { ProjectFormIdentityContext } from '../../../../../model/context/ProjectFormIdentityContext'
import { Button } from '../../../../../components/ui/button'
import { CheckIcon } from '@radix-ui/react-icons'
import { TechnicalFormStatusEnum } from 'shared'
import { Spinner } from '@chakra-ui/react'
import { QuickNavigationDropdown } from '../../../../../components/QuickNavigationDropdown'

interface Props extends PropsWithChildren {
  form: ProjectFormEnum
  initialValues: any
  title: string
}

export const TechnicalFormWrapper: React.FC<Props> = (props) => {
  const { project, id } = useProject()
  const technicalForm = useTechnicalReportForm(id, props.form, props.initialValues)

  if (!project || technicalForm.technicalFormIsLoading || technicalForm.technicalFormIsRefetching) {
    return (
      <div style={{ height: '9999px' }}>
        <ProjectLoadingSkeleton />
      </div>
    )
  }

  return (
    <ProjectLayout project={project}>
      <ProjectFormIdentityContext.Provider value={{ formEnum: props.form, projectId: id }}>
        <Formik
          initialValues={technicalForm.initialValues}
          onSubmit={(values) => {
            if (props.form === ProjectFormEnum.TECHNICAL_FORM_B1) {
              values.b1b.places = (values.b1b.places || []).map((place: any) => ({
                id: place.id,
                characteristics: place.characteristics,
              }))
            }

            technicalForm.storeTechnicalForm({ data: { ...values }, id })
          }}
        >
          {(formik) => {
            return (
              <Form>
                <TechnicalFormHeading
                  revertFromPreview={technicalForm.deletePreviewProjectTechnicalForm}
                  submitForPreview={async () => {
                    await formik.submitForm()
                    setTimeout(() => {
                      return technicalForm.submitForPreviewProjectForm()
                    }, 100)
                  }}
                  title={props.title}
                  isLoading={
                    technicalForm.storeTechnicalFormIsLoading ||
                    technicalForm.deletePreviewIsLoading ||
                    technicalForm.submitForPreviewIsLoading
                  }
                  isInPreviewMode={
                    technicalForm?.technicalForm?.data?.status === TechnicalFormStatusEnum.PREVIEW
                  }
                  projectId={project.id}
                />
                {technicalForm?.technicalForm?.data?.status === TechnicalFormStatusEnum.PREVIEW ? (
                  <div
                    className="p-3"
                    dangerouslySetInnerHTML={{
                      __html: technicalForm!.technicalForm?.data?.preview || '',
                    }}
                  ></div>
                ) : (
                  <div className="relative">
                    <div className="fixed bottom-[25px] right-10 z-10">
                      <div className="flex justify-end">
                        <QuickNavigationDropdown
                          options={[
                            {
                              label: 'a) Základní popis stavby',
                              target: 'b1a',
                            },
                            {
                              label: 'b) Charakteristika území a stavebního pozemku',
                              target: 'b1b',
                            },
                            {
                              label: 'c) Výjimky',
                              target: 'b1c',
                            },
                            {
                              label: 'd) Informace o vydaných stanoviscích',
                              target: 'b1d',
                            },
                            {
                              label: 'e) Průzkumy a rozbory',
                              target: 'b1e',
                            },
                            {
                              label: 'f) Ochrana území podle jiných právních předpisů',
                              target: 'b1f',
                            },
                            {
                              label:
                                'g) Poloha vzhledem k záplavovému území, poddolovanému území apod.',
                              target: 'b1g',
                            },
                            {
                              label: 'h) Vliv stavby na okolní',
                              target: 'b1h',
                            },
                            {
                              label: 'i) Požadavky na asanace, demolice, kácení dřevin',
                              target: 'b1i',
                            },
                            {
                              label: 'j) Požadavky na zábory',
                              target: 'b1j',
                            },
                            {
                              label: 'k) Územně technické podmínky',
                              target: 'b1k',
                            },
                            {
                              label: 'l) Věcné a časové vazby',
                              target: 'b1l',
                            },
                            {
                              label:
                                'm) Seznam pozemků podle katastru nemovitostí, na kterých se stavba umísťuje a provádí',
                              target: 'b1m',
                            },
                            {
                              label:
                                'n) Seznam pozemků podle katastru nemovitostí, na kterých vznikne ochranné nebo bezpečnostní pásmo',
                              target: 'b1n',
                            },
                          ]}
                        />
                      </div>
                    </div>
                    {props.children}
                    <div className="mt-5 flex justify-end">
                      <Button
                        type="button"
                        role="button"
                        onClick={async () => {
                          await formik.submitForm()
                          setTimeout(() => {
                            return technicalForm.submitForPreviewProjectForm()
                          }, 100)
                        }}
                      >
                        {technicalForm.submitForPreviewIsLoading ? (
                          <Spinner size="sm" />
                        ) : (
                          <>
                            <CheckIcon className="bg-white rounded-full text-blue-700 w-4 h-4 mr-3" />
                            Dokončit sekci
                          </>
                        )}
                      </Button>
                    </div>
                  </div>
                )}
              </Form>
            )
          }}
        </Formik>
      </ProjectFormIdentityContext.Provider>
    </ProjectLayout>
  )
}
