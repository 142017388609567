import React from 'react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { TabsInPills } from './TabsInPills'
import { FormikProps, useFormikContext } from 'formik'
import ReactRouterPrompt from 'react-router-prompt'
import {
  Modal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
} from '@chakra-ui/react'
import { Location } from 'react-router-dom'
import { RoutesEnum } from '../../../../model/enums/RoutesEnum'
import { SecondaryButton } from '../../../../components/SecondaryButton'
import { Button } from '../../../../components/ui/button'
import { TechnicalFormHeadingMenu } from './TechnicalFormHeadingMenu'
import { TaskGridDrawer } from '../../../Tasks/components/TaskGridDrawer'

interface Props {
  revertFromPreview: () => void
  submitForPreview: () => void
  title?: string
  isLoading?: boolean
  ctaButtons?: React.ReactNode
  isInPreviewMode?: boolean
  projectId: string
}

export const TechnicalFormHeading: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<any>

  return (
    <>
      <ReactRouterPrompt
        when={({ nextLocation }: { nextLocation: Location }) => {
          if (!formik.dirty) {
            return false
          }

          if (!nextLocation.pathname.includes(`${RoutesEnum.TECHNICAL_REPORT_FORM}`)) {
            return true
          }
        }}
        beforeConfirm={async () => {
          await formik.submitForm()
        }}
      >
        {({ onCancel, onConfirm, isActive }) => (
          <Modal isOpen={isActive} onClose={() => {}}>
            <ModalOverlay />
            <ModalContent>
              <ModalHeader>Opouštíte formulář který má neuložené změny</ModalHeader>
              <ModalFooter>
                <div className="flex gap-3">
                  <Button onClick={onConfirm}>Uložit a odejít</Button>
                  <SecondaryButton onClick={onCancel}>Zůstat</SecondaryButton>
                </div>
              </ModalFooter>
            </ModalContent>
          </Modal>
        )}
      </ReactRouterPrompt>
      <div className="z-10 sticky top-0 pt-3 border-b border-gray-100 pb-5 sm:pb-3 bg-white/80 backdrop-blur">
        <div className="flex md:items-center md:justify-between">
          <TabsInPills title={props.title} />
          <div className="mb-3 flex md:absolute md:right-0">
            {props.ctaButtons ? (
              props.ctaButtons
            ) : (
              <>
                <TaskGridDrawer
                  subtitle="Úkoly týkající se tohoto formuláře"
                  taskQuery={{ projectId: props.projectId }}
                />
                {props.isInPreviewMode ? (
                  <Button
                    size="sm"
                    type="button"
                    className="ml-3"
                    onClick={props.revertFromPreview}
                  >
                    {props.isLoading ? <Spinner size="sm" /> : 'Editovat'}
                  </Button>
                ) : (
                  <Button size="sm" type="submit" className="ml-3">
                    {props.isLoading ? <Spinner size="sm" /> : 'Uložit'}
                  </Button>
                )}
                <TechnicalFormHeadingMenu
                  isInPreviewMode={props.isInPreviewMode}
                  submitForPreview={props.submitForPreview}
                  revertFromPreview={props.revertFromPreview}
                  projectId={props.projectId}
                />
              </>
            )}
          </div>
        </div>
      </div>
      {!props.isInPreviewMode && (
        <div className="flex mt-3 mb-3">
          <h2 className="font-bold text-2xl">{props.title}</h2>
        </div>
      )}
    </>
  )
}
