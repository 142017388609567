import React from 'react'
import { Field, FormikProps } from 'formik'
import { RichTextInput } from '../../../../../components/inputs/RichTextInput/RichTextInput'
import { FormSectionTwoColumns } from '../../../../../components/forms/FormSectionTwoColumns'

interface Props {
  formik: FormikProps<any>
}

export const TechnicalFormB1N: React.FC<Props> = ({ formik }) => {
  return (
    <FormSectionTwoColumns title="n) Seznam pozemků podle katastru nemovitostí, na kterých vznikne ochranné nebo bezpečnostní pásmo" sectionKey="b1n">
      <div className="sm:col-span-6">
        <Field name="b1n.landList" component={RichTextInput} />
      </div>
    </FormSectionTwoColumns>
  )
}
