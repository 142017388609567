import React from 'react'
import { B1_INITIAL_VALUES } from 'shared'
import { TechnicalFormB1A } from './TechnicalFormB1A'
import { TechnicalFormB1B } from './TechnicalFormB1B'
import { TechnicalFormB1C } from './TechnicalFormB1C'
import { TechnicalFormB1D } from './TechnicalFormB1D'
import { TechnicalFormB1E } from './TechnicalFormB1E'
import { TechnicalFormB1F } from './TechnicalFormB1F'
import { TechnicalFormB1G } from './TechnicalFormB1G'
import { TechnicalFormB1H } from './TechnicalFormB1H'
import { TechnicalFormB1I } from './TechnicalFormB1I'
import { TechnicalFormB1J } from './TechnicalFormB1J'
import { TechnicalFormB1K } from './TechnicalFormB1K'
import { TechnicalFormB1L } from './TechnicalFormB1L'
import { TechnicalFormB1M } from './TechnicalFormB1M'
import { TechnicalFormB1N } from './TechnicalFormB1N'
import { FormikProps, useFormikContext } from 'formik'
import { TechnicalFormB1Av2 } from './TechnicalFormB1Av2'
import { TechnicalFormB1Bv2 } from './TechnicalFormB1Bv2'
import { TechnicalFormB1Cv2 } from './TechnicalFormB1Cv2'
import { TechnicalFormB1Dv2 } from './TechnicalFormB1Dv2'
import { TechnicalFormB1Ev2 } from './TechnicalFormB1Ev2'
import { TechnicalFormB1Fv2 } from './TechnicalFormB1Fv2'
import { TechnicalFormB1Gv2 } from './TechnicalFormB1Gv2'
import { TechnicalFormB1Hv2 } from './TechnicalFormB1Hv2'

interface Props {}

export const TechnicalReportFormB1: React.FC<Props> = (props) => {
  const formik = useFormikContext() as FormikProps<typeof B1_INITIAL_VALUES>

  return (
    <>
      <TechnicalFormB1Av2 formik={formik} />
      <TechnicalFormB1Bv2 formik={formik} />
      <TechnicalFormB1Cv2 formik={formik} />
      <TechnicalFormB1Dv2 formik={formik} />
      <TechnicalFormB1Ev2 formik={formik} />
      <TechnicalFormB1Fv2 formik={formik} />
      <TechnicalFormB1Gv2 formik={formik} />
      <TechnicalFormB1Hv2 formik={formik} />
      <TechnicalFormB1I formik={formik} />
      <TechnicalFormB1J formik={formik} />
      <TechnicalFormB1K formik={formik} />
      <TechnicalFormB1L formik={formik} />
      <TechnicalFormB1M formik={formik} />
      <TechnicalFormB1N formik={formik} />
    </>
  )
}
